import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { from } from "rxjs";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  Request,
  RequestMethod,
} from "@angular/http";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken = "";
  private isAuthenticated = false; // Set this value dynamically
  private isloggedIn: boolean = false;
  private loggedInUser: any;
  public headers: Headers;
  public options: RequestOptions;
  constructor(private http: Http, private router: Router) {
    this.authToken = sessionStorage.getItem("access_token");
    this.headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: this.authToken,
      "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, content-type, X-CSRF-TOKEN",
    });
    // this.headers.append('Accept', 'application/json')
    // this.headers.append('Content-Type', 'application/json')
    // this.headers.append('Access-Control-Allow-Origin', 'http://localhost:4200')
    this.options = new RequestOptions({ headers: this.headers });
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }
  isUserAuthenticated(url: string, apiParams: any) {
    this.authToken = sessionStorage.getItem("access_token");
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: this.authToken,
    });
    this.options = new RequestOptions({ headers: this.headers });
    let body = apiParams;
    return this.http
      .post(environment.API_ENDPOINT + url, body, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  isUserAuthenticatedGet(url: string) {
    this.authToken = sessionStorage.getItem("access_token");
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: this.authToken,
    });
    this.options = new RequestOptions({ headers: this.headers });
 
    return this.http
      .get(environment.API_ENDPOINT + url, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  isUserNonAuthenticated(url: string, apiParams: any) {
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
    this.options = new RequestOptions({ headers: this.headers });
    let body = apiParams;
    return this.http
      .post(environment.API_ENDPOINT + url, body, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  isUserLoggedIn(): boolean {
    var data = sessionStorage.getItem("IsUserlogged");
    if (data) {
      this.isloggedIn = true;
    } else {
      this.isloggedIn = false;
    }
    return this.isloggedIn;
  }
  logoutUser() {
    sessionStorage.removeItem("IsUserlogged");
    sessionStorage.removeItem("Apikey");
    sessionStorage.removeItem("CustId");
    sessionStorage.removeItem("IsActive");
    sessionStorage.removeItem("OrderId");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("Email");
    this.isloggedIn = false;
  }

  getData(url: any): Observable<any> {
    return from(
      fetch(
        environment.API_ENDPOINT + url, // the url you are trying to access
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET", // GET, POST, PUT, DELETE
          mode: "no-cors", // the most important option
        }
      )
    );
  }

  getFullItems(url: string) {
    this.authToken = sessionStorage.getItem("access_token");
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: this.authToken,
    });
    this.options = new RequestOptions({ headers: this.headers });

    return this.http
      .post(environment.API_ENDPOINT + url, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getAllItems(url: string, apiParams: any) {
    this.authToken = sessionStorage.getItem("access_token");
    this.headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: this.authToken,
    });
    this.options = new RequestOptions({ headers: this.headers });

    let body = apiParams;
    return this.http
      .get(environment.API_ENDPOINT + url, this.options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}
