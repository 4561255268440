import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { filter, map } from "rxjs/operators";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  _data: any;
  selectedRole: string;
  route: string;
  constructor(
    private router: Router,
    private location: Location,
    private titleService: Title,
    private activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activeRouter.firstChild;
          if (child.snapshot.data["title"]) {
            return child.snapshot.data["title"];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.selectedRole = "Admin";

    this._data = JSON.parse(sessionStorage.getItem("authUser"));

    if (this._data != null) {
      this.selectedRole = this._data.rolename;
    } else {
      this.route = this.location.path();
      if (this.route.includes("register")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("password-reset")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("verify-user")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("forget")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("prime-member-data")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("advantage-member-data")) {
        this.router.navigateByUrl(this.route);
      } else if (this.route.includes("signin")) {
        this.router.navigateByUrl(this.route);
      } else {
        this.router.navigateByUrl("login");
      }
    }
  }
}
