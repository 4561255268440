import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map'
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../../../environments/environment';
import {Http, Response, Headers, RequestOptions,Request, RequestMethod} from "@angular/http";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {

  forgetForm: FormGroup;message: string;showMsg:boolean;private timer: Observable<any>; private subscription: Subscription;
  constructor(private https: Http,private http: HttpClientModule, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService, private dialog: MatDialog) { }


  ngOnInit(){
    this.hideloader();
    this.forgetForm = new FormGroup({
      username: new FormControl('', [
        Validators.required 
      ])
    })
  }
  
  hideloader() { 
    document.getElementById('loading').style.display = 'none'; 
  }

  showloader() { 
    document.getElementById('loading').style.display = 'block'; 
  }

  forget(){
    this.showloader();
    const sregisterData = this.forgetForm.value;
    var apiParams={"Email":sregisterData.username};
    this.authsSrvice.isUserAuthenticated('ForgotPassword',apiParams).then((result:any)=>{
      // console.log(result);
      if(result.Result=="Success"){
        this.hideloader();
        this.message=result.Message;
        // this.showMsg= true; this.messages();this.forgetForm.reset();
        this.showMsg= true;
        this.openAlertDialog(result.Message, this.showMsg);
      }
      else{
        this.hideloader();
        this.message=result.Message;
        // this.showMsg= false;this.messages();
        this.showMsg= false;
        this.openAlertDialog(result.Message, this.showMsg);
      }
      setTimeout(() => {
        this.message = "";
      }, 5000);
    }).catch(error => console.log(error));
    
  }
  cancel(){
    this.router.navigateByUrl('login');
  }

  openAlertDialog(msg:any, flag:boolean) {
    const dialogRef = this.dialog.open(MessageDialogComponent,{
      data:{
        message: msg,
        flag:flag,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }
}
