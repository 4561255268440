import { Component, OnInit } from "@angular/core";
import { navItems, navItemsuser } from "../../_nav";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.css"],
})
export class DefaultLayoutComponent implements OnInit {
  anio: number = new Date().getFullYear();
  public sidebarMinimized = false;
  public navItems = navItems;
  public navItemsuser = navItemsuser;
  public selectedRole: string;
  constructor(private router: Router) {}
  userDisplayName = "";
  ngOnInit() {
    var authUser = sessionStorage.getItem("authUser");
    if (authUser !== null && authUser !== "undefined") {
      var _data = JSON.parse(authUser);
      this.userDisplayName = _data.Name;
      if (_data != null) {
        this.selectedRole = _data.Role;
      } else {
        this.router.navigateByUrl("signin");
      }
    }
  }

  logout() {
    sessionStorage.clear();

    this.router.navigateByUrl("signin");
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
