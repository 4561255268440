import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { SigninComponent } from "./views/signin/signin.component";
import { RegisterComponent } from "./views/register/register.component";
import { ForgetComponent } from "./views/forget/forget.component";
import { UpdatepasswordComponent } from "./views/updatepassword/updatepassword.component";
import { VerifyuserComponent } from "./views/verifyuser/verifyuser.component";
import { CompanydataComponent } from "./views/companydata/companydata.component";
import { AdvantagecompanydataComponent } from "./views/advantagecompanydata/advantagecompanydata.component";

//import { UserComponent } from './views/user/user.component';
export const routes: Routes = [
  {
    path: "",
    redirectTo: "signin",
    pathMatch: "full",
  },
  {
    path: "login",
    redirectTo: "signin",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    redirectTo: "dashboard",
    pathMatch: "full",
  },

  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404 | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500 | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "signin",
    component: SigninComponent,
    data: {
      title: "Login | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "prime-member-data",
    component: CompanydataComponent,
    data: {
      title: "Prime Memeber | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "advantage-member-data",
    component: AdvantagecompanydataComponent,
    data: {
      title: "Advantage Memeber | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register | Graphic Arts Alliance, LLC",
    },
  },

  {
    path: "password-reset",
    component: UpdatepasswordComponent,
    data: {
      title: "Update Password | Graphic Arts Alliance, LLC",
    },
  },

  {
    path: "verify-user",
    component: VerifyuserComponent,
    data: {
      title: "Verify User | Graphic Arts Alliance, LLC",
    },
  },
  {
    path: "forget",
    component: ForgetComponent,
    data: {
      title: "Forget Password | Graphic Arts Alliance, LLC",
    },
  },

  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Graphic Arts Alliance, LLC-Portal",
    },
    children: [
      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then((m) => m.WidgetsModule),
      },

      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then((m) => m.BaseModule),
      },
      {
        path: "buttons",
        loadChildren: () =>
          import("./views/buttons/buttons.module").then((m) => m.ButtonsModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then((m) => m.ChartJSModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: "theme",
        loadChildren: () =>
          import("./views/theme/theme.module").then((m) => m.ThemeModule),
      },

      {
        path: "addvendors",
        loadChildren: () =>
          import("./views/vendordetails/addvendor.module").then(
            (m) => m.AddvendorModule
          ),
        data: {
          title: "Add/Edit Vendor",
        },
      },
      {
        path: "managecustomer",
        loadChildren: () =>
          import("./views/manage-customers/manage-customers.module").then(
            (m) => m.ManageCustomersModule
          ),
        data: {
          title: "Manage Customers",
        },
      },
      {
        path: "user",
        loadChildren: () =>
          import("./views/user/user.module").then((m) => m.UserModule),
        data: {
          title: "Manage Users",
        },
      },
      {
        path: "adduser",
        loadChildren: () =>
          import("./views/user/adduser/adduser.module").then(
            (m) => m.AdduserModule
          ),
        data: {
          title: "Add/Edit User",
        },
      },

      {
        path: "managevendor",

        loadChildren: () =>
          import("./views/vendor/vendor.module").then((m) => m.VendorModule),

        data: {
          title: "Manage Customers",
        },
      },

      {
        path: "member-since",

        loadChildren: () =>
          import("./views/report/membersince/membersince-report.module").then(
            (m) => m.MembersinceReportModule
          ),

        data: {
          title: "Member Since",
        },
      },

      {
        path: "member-deactivation",

        loadChildren: () =>
          import(
            "./views/report/memberdeactivation/memberdeactivation-report.module"
          ).then((m) => m.MemberdeactivationReportModule),

        data: {
          title: "Member Deactivation",
        },
      },

      // {
      //   path:'addmember',
      //   loadChildren:()=>import('./views/AddMember/addmemberr.module').then(m=>m.AddmemberrModule),
      //   data:{
      //     title:'Add Member'

      //   }

      // },
      {
        path: "addmember",
        loadChildren: () =>
          import("./views/member/addmember/addmember.module").then(
            (m) => m.AddmemberModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "member",
        loadChildren: () =>
          import("./views/managemember/managemember/managemember.module").then(
            (m) => m.ManagememberModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "manageadvmember",
        loadChildren: () =>
          import(
            "./views/manageadvantagemember/manageadvantagemember.module"
          ).then((m) => m.ManageadvantagememberModule),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "addadvmember",
        loadChildren: () =>
          import(
            "./views/addmanageadvantagemember/addmanageadvantagemember.module"
          ).then((m) => m.AddmanageadvantagememberModule),
        data: {
          title: "Add edit member",
        },
      },
      {
        path: "managerebate",
        loadChildren: () =>
          import("./views/managerebate/managerebate.module").then(
            (m) => m.ManagerebateModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "addrebate",
        loadChildren: () =>
          import("./views/managerebate/addrebate/addrebate.module").then(
            (m) => m.AddrebateModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "viewrebate",
        loadChildren: () =>
          import("./views/managerebate/viewrebate/viewrebate.module").then(
            (m) => m.ViewrebateModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "viewsummary",
        loadChildren: () =>
          import("./views/managerebate/summary/summary.module").then(
            (m) => m.SummaryModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "actega",
        loadChildren: () =>
          import("./views/report/actega/actega.module").then(
            (m) => m.ActegaModule
          ),
        data: {
          title: "Add edit member",
        },
      },

      {
        path: "billQtr",
        loadChildren: () =>
          import("./views/report/billing-qtr/viewbilling-qtr.module").then(
            (m) => m.ViewbillingQtrModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "chainofcustody",
        loadChildren: () =>
          import(
            "./views/report/chainof-custody-report/chainof-custody-report.module"
          ).then((m) => m.ChainofCustodyReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "coatedboarduse",
        loadChildren: () =>
          import(
            "./views/report/coated-board-report/coated-board-report.module"
          ).then((m) => m.CoatedBoardReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "coatingreport",
        loadChildren: () =>
          import("./views/report/coating-report/coating-report.module").then(
            (m) => m.CoatingReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "ccoatedreport",
        loadChildren: () =>
          import(
            "./views/report/commmodity-coated-report/commmodity-coated-report.module"
          ).then((m) => m.CommmodityCoatedReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "companynamereport",
        loadChildren: () =>
          import(
            "./views/report/company-name-report/company-name-report.module"
          ).then((m) => m.CompanyNameReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "creditcardreport",
        loadChildren: () =>
          import(
            "./views/report/merchant-report/credit-card-merchant-report.module"
          ).then((m) => m.CreditCardMerchantReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "fujireport",
        loadChildren: () =>
          import("./views/report/fuji-report/fuji-report.module").then(
            (m) => m.FujiReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "inkvendorreport",
        loadChildren: () =>
          import("./views/report/ink-vendor/ink-vendor-report.module").then(
            (m) => m.InkVendorReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "wideformatreport",
        loadChildren: () =>
          import(
            "./views/report/wideformatreport/wideformat-report.module"
          ).then((m) => m.WideformatReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "kodakreport",
        loadChildren: () =>
          import("./views/report/kodakreport/kodak-report.module").then(
            (m) => m.KodakReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "merchantreport",
        loadChildren: () =>
          import("./views/report/merchantreport/merchant-report.module").then(
            (m) => m.MerchantReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "missoftwarereport",
        loadChildren: () =>
          import(
            "./views/report/missoftwarereport/missoftware-report.module"
          ).then((m) => m.MissoftwareReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "pressreport",
        loadChildren: () =>
          import("./views/report/pressreport/press-report.module").then(
            (m) => m.PressReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "priscoreport",
        loadChildren: () =>
          import("./views/report/priscoreport/prisco-report.module").then(
            (m) => m.PriscoReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "recyclewatereport",
        loadChildren: () =>
          import(
            "./views/report/recyclewastereport/recyclewaste-report.module"
          ).then((m) => m.RecyclewasteReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "rollervendorreport",
        loadChildren: () =>
          import(
            "./views/report/rollervendorreport/rollervendor-report.module"
          ).then((m) => m.RollervendorReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "sappivendorreport",
        loadChildren: () =>
          import(
            "./views/report/sappivendorreport/sappivendor-report.module"
          ).then((m) => m.SappivendorReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "taxreport",
        loadChildren: () =>
          import("./views/report/taxreport/tax-report.module").then(
            (m) => m.TaxReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "companyaddressreport",
        loadChildren: () =>
          import(
            "./views/report/companyaddress/companyaddress-report.module"
          ).then((m) => m.CompanyaddressReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "uncoatedreport",
        loadChildren: () =>
          import("./views/report/uncoatedreport/uncoated-report.module").then(
            (m) => m.UncoatedReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "sunchemicalreport",
        loadChildren: () =>
          import("./views/report/sunchemical/sunchemical-report.module").then(
            (m) => m.SunchemicalReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "uniformreport",
        loadChildren: () =>
          import("./views/report/uniformreport/uniform-report.module").then(
            (m) => m.UniformReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "vertivereport",
        loadChildren: () =>
          import("./views/report/veritivreport/veritiv-report.module").then(
            (m) => m.VeritivReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "webpresssoftreport",
        loadChildren: () =>
          import(
            "./views/report/webpresssoftreport/webpresssoft-report.module"
          ).then((m) => m.WebpresssoftReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "versoreport",
        loadChildren: () =>
          import("./views/report/versoreport/verso-report.module").then(
            (m) => m.VersoReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "quarterly-summary-report",
        loadChildren: () =>
          import(
            "./views/report/quarterlysummaryreport/quarterlysummary-report.module"
          ).then((m) => m.QuarterlysummaryReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },

      {
        path: "annual-summary-report",
        loadChildren: () =>
          import("./views/report/annualreport/annual-report.module").then(
            (m) => m.AnnualReportModule
          ),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "member-by-vendor-report",
        loadChildren: () =>
          import(
            "./views/report/memberbyvendorreport/memberbyvendor-report.module"
          ).then((m) => m.MemberbyvendorReportModule),
        data: {
          title: "Add edit billQtr",
        },
      },
      {
        path: "report",
        loadChildren: () =>
          import("./views/report/report.module").then((m) => m.ReportModule),
        data: {
          title: "Add edit member",
        },
      },
      {
        path: "anniversaryUVreport",
        loadChildren: () =>
          import(
            "./views/report/anniversaryreport/anniversary-report.module"
          ).then((m) => m.AnniversaryReportModule),
        data: {
          title: "Add edit member",
        },
      },
      {
        path: "ggamemberreport",
        loadChildren: () =>
          import("./views/report/ggareport/gga-report.module").then(
            (m) => m.GgaReportModule
          ),
        data: {
          title: "Add edit member",
        },
      },


      {
        path: "capability-report",
        loadChildren: () =>
          import(
            "./views/report/capabilities/capabilities-report.module"
          ).then((m) => m.CapabilitiesReportModule),
        data: {
          title: "Capability Report",
        },
      },


      {
        path: "quarterly-check-Member",
        loadChildren: () =>
          import("./views/report/ggareportexport/ggaexport-report.module").then(
            (m) => m.GgaexportReportModule
          ),
        data: {
          title: "Add edit member",
        },
      },
      // {
      //   path:'addvendor',
      //   loadChildren:()=>import('./views/vendor-add/addvendor.module').then(m=>m.AddvendorModule),
      //   data:{
      //     title:'Add Vendors'
      //   }
      // },

      {
        path: "contactexport",
        loadChildren: () =>
          import(
            "./views/report/contactexport/contactexport-report.module"
          ).then((m) => m.ContactexportReportModule),
        data: {
          title: "Contact Report",
        },
      },


      {
        path: "rebatedetails",
        loadChildren: () =>
          import(
            "./views/report/rebatedetailsreport/rebatedetails-report.module"
          ).then((m) => m.RebatedetailsReportModule),
        data: {
          title: "Rebate Details Report",
        },
      },


      {
        path: "addcustomer",
        loadChildren: () =>
          import(
            "./views/manage-customers/add-customer/add-customer.module"
          ).then((m) => m.AddCustomerModule),
        data: {
          title: "Add/Edit Customer",
        },
      },


      {
        path: "membersportal",
        loadChildren: () =>
          import(
            "./views/manageportalmember/manageportalmembermodule.module"
          ).then((m) => m.ManageportalmembermoduleModule),
        data: {
          title: "Manage Portal Member",
        },
      },
      {
        path: "addPortalMember",
        loadChildren: () =>
          import(
            "./views/manageportalmember/addedit/addeditmodule.module"
          ).then((m) => m.AddeditmoduleModule),
        data: {
          title: "Add/Edit Portal Member",
        },
      },

      {
        path: "managewebinar",
        loadChildren: () =>
          import(
            "./views/managewebinar/managewebinarmodule.module"
          ).then((m) => m.ManageportalmembermoduleModule),
        data: {
          title: "Manage Webinar",
        },
      },
      {
        path: "addWebinar",
        loadChildren: () =>
          import(
            "./views/managewebinar/addedit/addeditmodule.module"
          ).then((m) => m.AddeditmoduleModule),
        data: {
          title: "Add/Edit Webinar",
        },
      },
      {
        path: "manageVendorType",
        loadChildren: () =>
          import(
            "./views/managevendortype/managevendortypemodule.module"
          ).then((m) => m.ManagevendortypemoduleModule),
        data: {
          title: "Manage Vendor Type",
        },
      },
      {
        path: "addVendorType",
        loadChildren: () =>
          import(
            "./views/managevendortype/addedit/addeditmodule.module"
          ).then((m) => m.AddeditmoduleModule),
        data: {
          title: "Add/Edit Vendor Type",
        },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        data: {
          title: "Your Profile",
        },
      },


    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
