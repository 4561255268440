import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription'
import { parseString } from 'xml2js';
import * as xml2js from 'xml2js';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  appInterestList:any=[];
  idList:any=[];
  appInChacks:any="";

  registerForm: FormGroup;xml: string;message: string;showMsg:boolean;paramvalue:string;private timer: Observable<any>; private subscription: Subscription; public myrprocessor = [];
  constructor(private http: HttpClientModule,private route:ActivatedRoute, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService, private httpp:Http, private dialog:MatDialog) { }
  
 
  ngOnInit(){
    this.hideloader();
    
    this.registerForm = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),
      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),  
      username: new FormControl('', [
        Validators.required
      ]),
      name  : new FormControl('', [
        Validators.required
      ]),
      company :new FormControl(),

      title :new FormControl(),
      reason :new FormControl(),
      isActive: new FormControl(false)
    }),{ 
      Validators: this.password.bind(this)
    };

    this.GetprocessorList() ;
  }

  password(formGroup: FormGroup) {
    this.showloader();
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  error_messages = {
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length min 6.' },
      { type: 'maxlength', message: 'Password length min 6.' }
    ],
    'confirmpassword': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length min 6.' },
      { type: 'maxlength', message: 'Password length min 6.' }
    ],
  }


  GetprocessorList() {
  this.httpp.get(environment.API_ENDPOINT+`GetApplicationInterest`).subscribe((data:any)=>{
    if (data.status == 200){
      if (data['_body']){
        let _data = JSON.parse(data['_body']);
        let _appInterestList = []
        for(let i=0; i<_data.length; i++){
          _appInterestList.push({
            "ApplicationInterest":_data[i].ApplicationInterest,
            "InterestID":_data[i].InterestID
          })
        }
        this.appInterestList = _appInterestList;
      }
    }
  })
  }

  getChecked(e:any,id:any):void{
    if (e.checked == true){
      this.idList.push(id);
    }else{
      let list = this.idList.filter(e=>e!==id);
      this.idList = list;
    }
    this.appInChacks = this.idList.toString()
  }

  register(){
    this.showloader();
    const sregisterData = this.registerForm.value;
    var apiParams={
      "Name":sregisterData.name,
      "Email":sregisterData.username,
      "Title":sregisterData.title,
      "Company":sregisterData.company,
      "Reason":sregisterData.reason,
      "Password":sregisterData.password,
      "IsActive":true,
      "ApplicationInterest":this.appInChacks
    };

    if (sregisterData.password === sregisterData.confirmpassword){
      this.httpp.post(environment.API_ENDPOINT+`AddUser`, apiParams).subscribe((data:any)=>{
        if (data.status == 200){
          if (data._body){
            let _data = JSON.parse(data._body);
           
            if (_data.Result == "Success"){
              this.registerForm.reset();
              this.showMsg = true;
              this.router.navigateByUrl('login');
            }else{
              this.showMsg = false;
            }
            this.hideloader();
            this.message = _data.Message;
            this.openAlertDialog(_data.Message, this.showMsg);
          }
          setTimeout(() => {
            this.message = "";
          }, 5000);
        }
      })
    }else{
      this.showMsg = false;
      this.message = "Password and Confirm password are not matched.."
      this.openAlertDialog(this.message, this.showMsg);
      this.hideloader();
      setTimeout(() => {
        this.message = ""
      }, 5000);
    }
  }


  openAlertDialog(msg:any, flag:boolean) {
    const dialogRef = this.dialog.open(MessageDialogComponent,{
      data:{
        message: msg,
        flag:flag,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }


  cancel() {
    this.router.navigateByUrl('login');
  }
  hideloader() { 
    document.getElementById('loading').style.display = 'none'; 
  }

  showloader() { 
    document.getElementById('loading').style.display = 'block'; 
  }
}
