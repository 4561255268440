import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { AuthService } from "../../services/auth/auth.service";
import { HttpClientModule } from "@angular/common/http";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";
import { Subscription } from "rxjs/Subscription";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-dashboard",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  message: string;
  showMsg: boolean;
  private timer: Observable<any>;
  private subscription: Subscription;
  constructor(
    private http: HttpClientModule,
    private router: Router,
    private fb: FormBuilder,
    private authsSrvice: AuthService,
    private httpp: Http,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.hide();
    this.signinForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50),
      ]),

      rememberMe: new FormControl(false),
    });

    //     this.ApplicationKey=sessionStorage.getItem('Apikey');
    // if(this.ApplicationKey){
    //   this.router.navigateByUrl('orders');
    // }
  }

  signin() {
    this.spinner.show();
    const signinData = this.signinForm.value;
    var apiParams = {
      Email: signinData.username,
      Password: signinData.password,
    };

    // const formData = new FormData;
    // formData.append('Email', signinData.username);
    // formData.append('Password', signinData.password);
    // formData.append('Role','Admin');
    this.authsSrvice
      .isUserAuthenticated("Login", apiParams)
      .then((result) => {
        // this.httpp.post(environment.API_ENDPOINT+`Login`, formData).subscribe((result:any)=>{

        if (result.Result == "Success") {
          this.spinner.hide();
          this.message = result.Message;
          this.showMsg = true;
          // this.openAlertDialog(result.Message, this.showMsg);
          // result.Email=signinData.email;
          var authUser = JSON.stringify(result);

          sessionStorage.setItem("authUser", authUser);
          sessionStorage.setItem("Email", signinData.email);
          sessionStorage.setItem("IsUserlogged", result.Result);
          sessionStorage.setItem("access_token", result.access_token);
          this.toastr.success(result.Message, "Well done...");
          this.router.navigateByUrl("dashboard");
        } else {
          this.message = result.Message;
          this.showMsg = false;
          // this.signinForm.reset();
          this.toastr.error(result.Message, "Error");
          this.spinner.hide();
        }
        setTimeout(() => {
          this.message = "";
        }, 5000);
      })
      .catch((error) => console.log(error));
  }

  register() {
    this.router.navigateByUrl("register");
  }
  forget() {
    this.router.navigateByUrl("forget");
  }
  signup() {
    this.router.navigateByUrl("register");
  }
}
