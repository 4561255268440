import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-dashboard',
  templateUrl: './eslsignin.component.html',
  styleUrls: ['./eslsignin.component.css']
})
export class EslsigninComponent implements OnInit {

  signinForm: FormGroup;
  message: string;showMsg:boolean;private timer: Observable<any>;private subscription: Subscription;
  constructor(private http: HttpClientModule, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService) { }


  ngOnInit(): void {
    this.hideloader();
    this.signinForm = new FormGroup({
      username: new FormControl('', [
        Validators.required
       
          ]),
          password: new FormControl('', [Validators.required,
            Validators.minLength(6),Validators.maxLength(50)
          ]),
     
      rememberMe: new FormControl(false)
	  
    })
  }



  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}




signin() {
  this.showloader();
  const signinData = this.signinForm.value;
  var apiParams={"Email":signinData.username,"Password":signinData.password};
  
  this.authsSrvice.isUserAuthenticated('Login',apiParams).then((result)=>{
    console.log(result);
    if(result.Result=="Success"){
      this.hideloader();
      this.message=result.Message;
      this.showMsg= true;
      // result.Email=signinData.email;
      var authUser = JSON.stringify(result);
      sessionStorage.setItem('authUser', authUser);
      sessionStorage.setItem('Email',signinData.email);
      sessionStorage.setItem('IsUserlogged',result.Result);
      this.router.navigateByUrl('dashboard');
      
    }else{
      this.message=result.Message;
      this.showMsg= false; this.signinForm.reset();
      this.hideloader();
    }
    setTimeout(() => {
      this.message = "";
    }, 5000);
    }).catch(error => console.log(error));
}

register() {
  this.router.navigateByUrl('register');
}
forget(){
  this.router.navigateByUrl('forget');
}
signup()  
{
this.router.navigateByUrl('sleregister');
}

}
