import {
  Component,
  OnInit,
  SecurityContext,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { AuthService } from "../../services/auth/auth.service";
import { HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import { getStyle } from "@coreui/coreui/dist/js/coreui-utilities";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";
import { Subscription } from "rxjs/Subscription";

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  Request,
  RequestMethod,
} from "@angular/http";

@Component({
  selector: "app-advantagecompanydata",
  templateUrl: "./advantagecompanydata.component.html",
  styleUrls: ["./advantagecompanydata.component.css"],
})
export class AdvantagecompanydataComponent implements OnInit {
  registerForm: FormGroup;
  message: string;
  showMsg: boolean;
  name: string = "";
  public authToken = "";
  public headers: Headers;
  public options: RequestOptions;
  constructor(
    private httpp: Http,
    private http: HttpClientModule,
    private router: Router,
    private fb: FormBuilder,
    private authsSrvice: AuthService,
    private titleService: Title
  ) {}

  currPage: any;
  envNumb: any;
  public myusers = [];
  totalPageCount: any;
  prevPage: any;
  nextPage: any;
  pageOfItems: Array<any>;
  items = [];
  public showTable = false;
  _data: any;
  public show = false;
  public Pagearray = [];
  selectedRole: string;
  public userId = 0;
  searchtext: any;

  ngOnInit(): void {
    this.currPage = 1;
    this.titleService.setTitle(
      "Advantage Members | Graphic Arts Alliance, LLC"
    );
    this.prevPage = false;

    this.envNumb = environment.paginateNumb;
    (this.registerForm = new FormGroup({
      search: new FormControl(this.searchtext),
      Active: new FormControl("True"),
    })),
      this.GetusersList(this.currPage);
  }

  onSearch(event: any) {
    const AddUserData = this.registerForm.value;
    if (AddUserData.search == "") {
      this.GetusersList(this.currPage);
    }
  }
  GetusersList(page) {
    //this.spinner.show();
    this.prevPage = page - 1;
    this.nextPage = page + 1;
    this.currPage = page;
    const AddUserData = this.registerForm.value;
    var ordersParams = {};

    var apiParams = {
      Page: page,
      Search: AddUserData.search,
      Isprimemember: false,
      searchIsActive: AddUserData.Active,
    };
    this.authsSrvice
      .isUserNonAuthenticated("GetCompany", apiParams)
      .then((result) => {
        this.myusers = result.CompanyDetailslist;
        this.totalPageCount = result.TotalPageCount;

        let lan = Number(this.totalPageCount / this.envNumb).toString();
        let point = (this.totalPageCount / this.envNumb)
          .toString()
          .split(".")[1];
        if (Number(point) > 0 && Number(point) < 5) {
          //lan = (Number(lan)+1).toString();
        }
        let arr = [];
        for (let i = 0; i < Number(lan); i++) {
          arr.push(i + 1);
        }
        this.Pagearray = arr;
        this.showTable = true;
        this.show = false;
        if (page >= Number(this.totalPageCount / this.envNumb)) {
          this.nextPage = false;
        }
        // this.spinner.hide();
      });
  }
}
