import { INavData } from "@coreui/angular";

export const navItemsuser: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    name: "Manage Users",
    url: "/user",
    icon: "icon-people",
  },
  {
    name: "Manage Vendors",
    url: "/managevendor",
    icon: "icon-layers",
  },
  {
    name: "Prime Members",
    url: "/member",
    icon: "fa fa-users",
  },

  {
    name: "Advantage Members",
    url: "/manageadvmember",
    icon: "icon-user-following",
  },
  {
    name: "Manage Rebates",
    url: "/managerebate",
    icon: "icon-graduation",
  },
  {
    name: "Manage Vendor Type",
    url: "/manageVendorType",
    icon: "icon-graduation",
  },
  {
    name: "Manage Members",
    url: "/membersportal",
    icon: "icon-screen-desktop",
  },
  {
    name: "Manage Webinars",
    url: "/managewebinar",
    icon: "icon-organization",
  },
  {
    name: "Reports",
    url: "/report",
    icon: "icon-docs",
  },

 
  
];

export const navItems: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    name: "Manage Users",
    url: "/user",
    icon: "icon-people",
  },

  {
    name: "Manage Vendors",
    url: "/managevendor",
    icon: "icon-layers",
  },
  {
    name: "Manage Vendors Type",
    url: "/manageVendorType",
    icon: "icon-docs",
  },
  {
    name: "Prime Members",
    url: "/member",
    icon: "fa fa-users",
  },

  {
    name: "Advantage Members",
    url: "/manageadvmember",
    icon: "icon-user-following",
  },

  {
    name: "Manage Rebates",
    url: "/managerebate",
    icon: "icon-graduation",
  },
  {
    name: "Manage Members",
    url: "/membersportal",
    icon: "icon-screen-desktop",
  },
  {
    name: "Manage Webinars",
    url: "/managewebinar",
    icon: "icon-organization",
  },
  {
    name: "Reports",
    url: "/report",
    icon: "icon-docs",
  },

];
