import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  flag:boolean;
  message: string = ""
  cancelButtonText = "Cancel"
  redirectUrl:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MessageDialogComponent>, private router:Router) {
    if (data) {
     
      this.message = data.message || this.message;
      this.flag = data.flag;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      // if (data.urlParam !== undefined && data.urlParam !== ""){
      //   this.redirectUrl = data.urlParam;
      // }
    }
    this.dialogRef.updateSize('300vw','300vw')
    setTimeout(() => {
      this.onConfirmClick();
    }, 5000);
  }
  
  ngOnInit(): void {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
    // if (this.redirectUrl !== undefined){
    //   console.log(this.redirectUrl);
    //   this.router.navigateByUrl(this.redirectUrl);
    // }
  }

}
